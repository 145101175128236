import { Tooltip } from "primereact/tooltip";

interface AvkTooltipProps {
	content: React.ReactNode;
	tooltipContent: React.ReactNode;
	matchingClassName: string;
	showOnFocus?: boolean;
}

const AvkTooltip = (props: AvkTooltipProps) => {
	return (
		<>
			<Tooltip
				event={props.showOnFocus ? "focus" : undefined}
				target={"." + props.matchingClassName}
				className="mb-5"
				position="top"
			>
				{props.tooltipContent}
			</Tooltip>
			{props.content}
		</>
	);
};

export default AvkTooltip;
