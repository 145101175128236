import { Toast, ToastMessage } from "primereact/toast";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
	NotificationObject,
	removeNotification,
	setNotificationAsDisplayed,
} from "../../../redux/slices/notification-slice";
import { store } from "../../../redux/store";
import { isNullOrUndefined } from "../../../utils/CommonFunctions";
import styles from "./AvkNotification.module.css";

const AvkNotification = () => {
	const topRightToastRef = useRef<Toast>(null);
	const topWideToastRef = useRef<Toast>(null);

	const notifications: NotificationObject[] = useSelector(
		(props: any) => props.notification.notifications
	);

	useEffect(() => {
		notifications
			.filter(
				(notification: NotificationObject) => notification.removeFromPage
			)
			.forEach((notificationContent: NotificationObject) => {
				if (notificationContent.position === "top-wide") {
					notificationContent.displayedNotification &&
						removeNotificationFromPage(
							notificationContent.displayedNotification,
							topWideToastRef
						);
				} else {
					notificationContent.displayedNotification &&
						removeNotificationFromPage(
							notificationContent.displayedNotification,
							topRightToastRef
						);
				}
			});
		notifications
			.filter((notification: NotificationObject) =>
				isNullOrUndefined(notification.displayedNotification)
			)
			.forEach((notificationContent: NotificationObject) => {
				let displayedNotification: ToastMessage;
				if (notificationContent.position === "top-wide") {
					displayedNotification = showNotification(
						notificationContent,
						topWideToastRef
					);
				} else {
					displayedNotification = showNotification(
						notificationContent,
						topRightToastRef
					);
				}
				store.dispatch(
					setNotificationAsDisplayed({
						id: notificationContent.id,
						displayedNotification: displayedNotification,
					})
				);
			});
	}, [notifications]);

	const showNotification = (
		notificationContent: NotificationObject,
		toastRef: React.RefObject<Toast>
	): ToastMessage => {
		const messageToShow: ToastMessage = {
			id: notificationContent.id,
			severity: notificationContent.type,
			summary: notificationContent.title,
			detail: notificationContent.message,
			life: notificationContent.showTime,
			sticky: notificationContent.sticky,
			content: notificationContent.customContent,
			closable: !notificationContent.showAlways,
		};
		toastRef.current && toastRef.current.show(messageToShow);
		return messageToShow;
	};

	const removeNotificationFromPage = (
		notificationMessage: ToastMessage,
		toastRef: React.RefObject<Toast>
	) => {
		toastRef.current && toastRef.current.remove(notificationMessage);
	};

	const removeNotificationConstantly = (notification: ToastMessage) => {
		store.dispatch(removeNotification(notification.id ?? ""));
	};

	return (
		<>
			<Toast
				ref={topRightToastRef}
				position="top-right"
				className="mt-7"
				style={{ zIndex: "2000 !important" }}
				onRemove={removeNotificationConstantly}
			/>
			<Toast
				ref={topWideToastRef}
				position="top-center"
				className={"z-1 mt-6 w-full " + styles.noMaxWidth}
				onRemove={removeNotificationConstantly}
				pt={{
					message: () => ({
						className:
							"flex align-items-center justify-content-center w-full",
					}),
					content: () => ({
						className:
							"flex align-items-center justify-content-center w-full",
					}),
					closeButton: () => ({
						className: "ml-5",
					}),
				}}
			/>
		</>
	);
};

export default AvkNotification;
