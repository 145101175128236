export const isNullOrUndefined = (val: any): boolean => {
	return val === null || val === undefined;
};

export const isNullOrUndefinedOrEmpty = (val?: string): boolean => {
	return val === null || val === undefined || val.trim() === "";
};

export const clearWhiteSpace = (value: string): string | undefined => {
	return isNullOrUndefinedOrEmpty(value) ? undefined : value;
};

export const getImageSrcUrlFromBase64String = (
	value: string,
	type: string
): string => {
	let byteCharacters = atob(value);
	let byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	let blob = new Blob([new Uint8Array(byteNumbers)], { type: type });
	return URL.createObjectURL(blob);
};

export const getBase64FromImageUrl = async (
	imageUrl: string,
	setBase64: (base64Value: string) => void
) => {
	const blobValue = await fetch(imageUrl).then((r) => r.blob());
	const reader = new FileReader();
	reader.readAsDataURL(blobValue);
	reader.onloadend = () => {
		setBase64(reader.result + "");
	};
};

export const checkTckn = (tckno: string): boolean => {
	if (tckno.length !== 11 || tckno.startsWith("0") || !/^\d+$/.test(tckno)) {
		return false;
	}

	const tcknAsInt = parseInt(tckno, 10);

	const digit11 = tcknAsInt % 10;
	const digit10 = ((tcknAsInt - (tcknAsInt % 10)) % 100) / 10;
	const digit9 = ((tcknAsInt - (tcknAsInt % 100)) % 1000) / 100;
	const digit8 = ((tcknAsInt - (tcknAsInt % 1000)) % 10000) / 1000;
	const digit7 = ((tcknAsInt - (tcknAsInt % 10000)) % 100000) / 10000;
	const digit6 = ((tcknAsInt - (tcknAsInt % 100000)) % 1000000) / 100000;
	const digit5 = ((tcknAsInt - (tcknAsInt % 1000000)) % 10000000) / 1000000;
	const digit4 = ((tcknAsInt - (tcknAsInt % 10000000)) % 100000000) / 10000000;
	const digit3 =
		((tcknAsInt - (tcknAsInt % 100000000)) % 1000000000) / 100000000;
	const digit2 =
		((tcknAsInt - (tcknAsInt % 1000000000)) % 10000000000) / 1000000000;
	const digit1 =
		((tcknAsInt - (tcknAsInt % 10000000000)) % 100000000000) / 10000000000;

	if (
		(digit1 +
			digit3 +
			digit5 +
			digit7 +
			digit9 +
			digit2 +
			digit4 +
			digit6 +
			digit8 +
			digit10) %
			10 !==
		digit11
	) {
		return false;
	}

	if (
		((digit1 + digit3 + digit5 + digit7 + digit9) * 7 +
			(digit2 + digit4 + digit6 + digit8) * 9) %
			10 !==
		digit10
	) {
		return false;
	}

	if (((digit1 + digit3 + digit5 + digit7 + digit9) * 8) % 10 !== digit11) {
		return false;
	}

	return true;
};
