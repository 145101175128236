import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ToastMessage } from "primereact/toast";
import React from "react";

export type NotificationDisplayedObject = {
	id: string;
	displayedNotification?: ToastMessage;
};

export type NotificationObject = {
	id: string;
	displayedNotification?: ToastMessage;
	title?: string;
	message?: string;
	type?: "success" | "info" | "warn" | "error";
	position?: "top-wide" | "top-left";
	showTime?: number;
	sticky?: boolean;
	showAlways?: boolean;
	customContent?: React.ReactNode;
	removeFromPage?: boolean;
	removeOnLogOut?: boolean;
};

export const notificationSlice = createSlice({
	name: "notification",
	initialState: {
		notifications: [] as NotificationObject[],
	},
	reducers: {
		addNotification: (state, action: PayloadAction<NotificationObject>) => {
			state.notifications.push(action.payload);
		},
		setNotificationAsDisplayed: (
			state,
			action: PayloadAction<NotificationDisplayedObject>
		) => {
			const notification = state.notifications.find(
				(x) => x.id === action.payload.id
			);
			if (notification) {
				notification.displayedNotification =
					action.payload.displayedNotification;
			}
		},
		removeNotificationFromPage: (state, action: PayloadAction<string>) => {
			const notification = state.notifications.find(
				(x) => x.id === action.payload
			);
			if (notification) {
				notification.removeFromPage = true;
			}
		},
		removeNotification: (state, action: PayloadAction<string>) => {
			const index = state.notifications.findIndex(
				(x) => x.id === action.payload
			);
			if (index !== -1) {
				state.notifications.splice(index, 1);
			}
		},
		removeNotificationAfterLogOut: (state) => {
			state.notifications.forEach((notification) => {
				if (notification.removeOnLogOut) {
					notification.removeFromPage = true;
				}
			});
		},
	},
});

export const {
	addNotification,
	setNotificationAsDisplayed,
	removeNotificationFromPage,
	removeNotification,
	removeNotificationAfterLogOut,
} = notificationSlice.actions;
