import FilePresentIcon from "@mui/icons-material/FilePresent";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { certificateClient } from "../../../api/services";
import AvkButton from "../../../components/avk-components/AvkButton";
import AvkCard from "../../../components/avk-components/AvkCard";
import AvkInputText from "../../../components/avk-components/AvkInputText";
import CertificateVerificationRequest from "../../../dto/request/CertificateVerificationRequest";
import CertificateVerifyFullResponse from "../../../dto/response/CertificateVerifyResponse";
import {
	addToRecaptchaIdList,
	removeFromRecaptchaIdList,
	removeRecaptchaToken,
} from "../../../redux/slices/recaptcha-slice";
import { store } from "../../../redux/store";
import {
	getImageSrcUrlFromBase64String,
	isNullOrUndefinedOrEmpty,
} from "../../../utils/CommonFunctions";
import { CertificateVerifyValidation } from "./CertificateVerifyValidation";

interface CertificateVerifyFormikObject {
	identifier: string;
	certificateNumber: string;
}

const initialValues: CertificateVerifyFormikObject = {
	identifier: "",
	certificateNumber: "",
};

const CertificateVerifyPage = () => {
	const history = useHistory();

	const recaptchaToken: string = useSelector(
		(props: any) => props.recaptcha.token
	);

	const { handleSubmit, getFieldProps, submitForm, errors } = useFormik({
		initialValues: initialValues,
		validateOnBlur: true,
		validateOnChange: true,
		validationSchema: CertificateVerifyValidation,
		onSubmit: (values) => {
			if (!isNullOrUndefinedOrEmpty(recaptchaToken)) {
				certificateClient
					.verify({
						uniqueKey: values.identifier,
						certificateNumber: values.certificateNumber,
						recaptchaToken: recaptchaToken,
					} as CertificateVerificationRequest)
					.then(({ data }: CertificateVerifyFullResponse) => {
						history.push({
							pathname: `/certificate/verified`,
							state: {
								file: getImageSrcUrlFromBase64String(
									data.certificateFile.toString(),
									"application/pdf"
								),
							},
						});
					})
					.finally(() => {
						store.dispatch(removeRecaptchaToken());
					});
			}
		},
	});

	useEffect(() => {
		if (!isNullOrUndefinedOrEmpty(recaptchaToken)) {
			submitForm();
		}
	}, [recaptchaToken]); // eslint-disable-line

	const recaptchaButtonId = "checkButton";
	useEffect(() => {
		store.dispatch(addToRecaptchaIdList(recaptchaButtonId));
		return () => {
			store.dispatch(removeFromRecaptchaIdList(recaptchaButtonId));
		};
	}, []); // eslint-disable-line

	const divClassName = "w-full mb-2";
	return (
		<div className="flex align-items-center justify-content-center flex-column h-full w-full">
			<AvkCard>
				<div className="flex align-items-center justify-content-center flex-column h-full w-full px-5 md:px-8">
					<span className="text-center align-items-center font-bold text-3xl md:text-5xl mt-2 mb-6">
						Sertifika Doğrulama
					</span>
					<div className="flex align-items-start justify-content-start flex-column h-full w-full lg:w-30rem">
						<form
							className="px-1 md:px-3 w-full h-full"
							onSubmit={handleSubmit}
						>
							<span className="text-2xl w-full">Belge sahibinin;</span>
							<div className={divClassName}>
								<AvkInputText
									id={"identifier"}
									fullwidth
									label="TC Kimlik No / Pasaport No"
									{...getFieldProps("identifier")}
									groupIconElement={<FingerprintIcon />}
									error={errors.identifier !== undefined}
								/>
							</div>
							<div className={divClassName}>
								<AvkInputText
									fullwidth
									id="certificateNumber"
									label="Sertifika Numarası (Son 10 hane)"
									{...getFieldProps("certificateNumber")}
									groupIconElement={<FilePresentIcon />}
									error={errors.certificateNumber !== undefined}
								/>
							</div>
							<div className={divClassName}>
								<AvkButton
									fullwidth
									id={recaptchaButtonId}
									name={recaptchaButtonId}
									label="SORGULA"
									type="submit"
									className="mt-4"
								/>
							</div>
						</form>
					</div>
				</div>
			</AvkCard>
		</div>
	);
};

export default CertificateVerifyPage;
