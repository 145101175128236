import { createSlice } from "@reduxjs/toolkit";

export type AuthSliceObject = {
	redirectTo: string;
};

export const authSlice = createSlice({
	name: "auth",
	initialState: {
		redirectTo: "",
	} as AuthSliceObject,
	reducers: {
		setRedirectTo: (state, action) => {
			state.redirectTo = action.payload;
		},
		clearRedirectTo: (state) => {
			state.redirectTo = "";
		},
	},
});

export const { setRedirectTo, clearRedirectTo } = authSlice.actions;
