import { Image } from "primereact/image";

interface AvkImageProps {
	src: string | undefined;
	id?: string;
	width?: string;
	height?: string;
	className?: string;
	imageStyle?: React.CSSProperties;
	onClick?: (event: any) => void;
}

const AvkImage = (props: AvkImageProps) => {
	return (
		<Image
			id={props.id}
			src={props.src}
			width={props.width}
			height={props.height}
			className={props.className}
			imageStyle={props.imageStyle}
			onClick={props.onClick}
		/>
	);
};

export default AvkImage;
