import { AvkDataListViewData } from "../AvkDataListView/AvkDataListObjects";

interface AvkDataListViewTemplateProps {
	data: AvkDataListViewData;
	events?: (id: number, title?: string) => React.ReactNode;
}

const AvkDataListViewTemplate = (props: AvkDataListViewTemplateProps) => {
	return (
		<div className="w-12 mx-3 mr-5 my-2 pl-4 pt-3 shadow-5 ">
			<div className="grid">
				<div className="col-10 lg:col-11">
					<div className="grid">
						<div className="col-12 lg:col-7 font-bold">
							{props.data.title}
						</div>
						<div className="col-12 lg:col-5 text-left lg:text-right">
							{props.data.content}
						</div>
					</div>
				</div>
				<div className="col-2 lg:col-1 flex justify-content-center align-content-center flex-wrap">
					{props.events &&
						props.events(
							props.data.id,
							(props.data.title as string)
								? (props.data.title as string)
								: undefined
						)}
				</div>
			</div>
		</div>
	);
};

export default AvkDataListViewTemplate;
