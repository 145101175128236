import { useSelector } from "react-redux";
import { SiteObject } from "../../../redux/slices/site-slice";
import AvkButton from "../../avk-components/AvkButton";

const SemFooter = () => {
	const siteValues: SiteObject = useSelector((props: any) => props.site);
	return (
		<div className="flex flex-column">
			<div className="flex align-items-center justify-content-center">
				<span className="text-sm px-1">{siteValues.phone}</span>
				|
				<AvkButton
					label="Bilgi Edinme"
					link
					className="text-black-alpha-90 text-sm m-0 py-0 px-1"
					onClick={() => {
						window.open(siteValues.moreInformationUrl);
					}}
				/>
				|
				<AvkButton
					label="KVKK"
					link
					className="text-black-alpha-90 text-sm m-0 py-0 px-1"
					onClick={() => {
						window.open(siteValues.kvkkUrl);
					}}
				/>
			</div>
			<div className="flex align-items-center justify-content-center mt-1">
				<span className="text-sm pr-2">Sertifika Yönetim Sistemi</span>
				<span className="text-sm"> - </span>
				<AvkButton
					label="Avkar Yazılım © 2024"
					link
					className="text-black-alpha-90 text-sm m-0 py-0 pl-2"
					onClick={() => {
						window.open("https://avkaryazilim.com.tr/tr.html");
					}}
				/>
			</div>
		</div>
	);
};

export default SemFooter;
