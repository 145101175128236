import LoginRequest from "../../../dto/request/LoginRequest";
import ResetPasswordRequest from "../../../dto/request/ResetPasswordRequest";
import { LoginInformationResponse } from "../../../dto/response/LoginInformationResponse";
import { SuccessResponse } from "../../../dto/response/SuccessResponse";
import { ApiClient } from "../../ApiConfig";

let client = ApiClient.getInstance();

const authClient = {
	login(request: LoginRequest) {
		return client.post<LoginInformationResponse>("/auth/login", request);
	},
	logout() {
		return client.get("/auth/logout");
	},
	resetPasswordEmail(uniqueKey: string) {
		return client.get<SuccessResponse>("/auth/reset-password/" + uniqueKey);
	},
	checkResetPassword(validationKey: string, resetPasswordCode: string) {
		return client.get<SuccessResponse>(
			"/auth/reset-password/" + validationKey + "/" + resetPasswordCode
		);
	},
	resetPassword(resetPasswordRequest: ResetPasswordRequest) {
		return client.post("/auth/reset-password", resetPasswordRequest);
	},
};

export default authClient;
