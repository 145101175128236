import { ReactElement } from "react";
import SemBody from "../components/sem-components/SemBody";
import SemFooter from "../components/sem-components/SemFooter";
import SemHeader from "../components/sem-components/SemHeader";
import SemLeftMenu from "../components/sem-components/SemLeftMenu";
import SemRightMenu from "../components/sem-components/SemRightMenu";
import styles from "./Layout.module.css";

interface LayoutProps {
	children: ReactElement;
}

const Layout = (props: LayoutProps) => {
	return (
		<div className={styles.rootElement}>
			<header className={styles.headerElement}>
				<SemHeader />
			</header>
			<main className={styles.contentElement}>
				<SemBody
					childrenLeftPanel={<SemLeftMenu />}
					childrenRightPanel={<SemRightMenu />}
				>
					{props.children}
				</SemBody>
			</main>
			<footer className={styles.footerElement}>
				<SemFooter />
			</footer>
		</div>
	);
};

export default Layout;
