import { FunctionComponent, ReactNode } from "react";
interface DetailPageContainerProps {
	label: string;
	content?: boolean;
	children?: ReactNode;
	className?: string;
}
const DetailPageContainer: FunctionComponent<DetailPageContainerProps> = (
	props
) => {
	return (
		<div className="flex flex-column align-items-start w-full justify-content-start p-2">
			<div
				className={`mt-2 text-xl w-full text-divider ${
					props.content && "font-bold"
				}
				 `}
			>
				{props.label}
			</div>
			<div
				className={`text-l w-full ${!props.content ? "font-bold" : ""} ${
					props.className ? props.className : ""
				}`}
			>
				{props.children}
			</div>
		</div>
	);
};
export default DetailPageContainer;
