import CertificateVerificationRequest from "../../../dto/request/CertificateVerificationRequest";
import { CertificateVerifyResponse } from "../../../dto/response/CertificateVerifyResponse";
import { UserCertificatesResponse } from "../../../dto/response/UserCertificatesResponse";
import { ApiClient } from "../../ApiConfig";

let client = ApiClient.getInstance();

const certificateClient = {
	getUserCertificates() {
		return client.get<UserCertificatesResponse>("/certificates");
	},
	getCertificateByProgram(programCode: string) {
		return client.getBlob("/certificates/program/" + programCode);
	},
	verify(request: CertificateVerificationRequest) {
		return client.post<CertificateVerifyResponse>(
			"/certificates/verify",
			request
		);
	},
};
export default certificateClient;
