import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/primereact.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import App from "./App";
import siteClient from "./api/services/site";
import SiteInformationFullResponse from "./dto/response/SiteInformationResponse";
import "./index.css";
import { setSiteKey } from "./redux/slices/recaptcha-slice";
import { setSiteInfo } from "./redux/slices/site-slice";
import { setUserLoggedIn, setUserLoggedOut } from "./redux/slices/user-slice";
import { store } from "./redux/store";
import {
	getImageSrcUrlFromBase64String,
	isNullOrUndefinedOrEmpty,
} from "./utils/CommonFunctions";

function render() {
	siteClient
		.getSiteInformation()
		.then(({ data }: SiteInformationFullResponse) => {
			store.dispatch(
				setSiteInfo({
					logoBase64: data.logo.toString(),
					title: data.title,
					phone: data.phone,
					kvkkUrl: data.kvkkUrl,
					courseApplyActive: data.courseApplyActive,
					moreInformationUrl: data.moreInformationUrl,
				})
			);

			if (!isNullOrUndefinedOrEmpty(data.recaptchaSiteKey)) {
				store.dispatch(setSiteKey(data.recaptchaSiteKey));
			}

			setIconToTab(
				getImageSrcUrlFromBase64String(data.logo.toString(), "image/jpeg")
			);

			let name = data.loginInformation.name;
			let surname = data.loginInformation.surname;
			let email = data.loginInformation.email;
			if (
				isNullOrUndefinedOrEmpty(name) &&
				isNullOrUndefinedOrEmpty(surname) &&
				isNullOrUndefinedOrEmpty(email)
			) {
				store.dispatch(setUserLoggedOut());
			} else {
				store.dispatch(
					setUserLoggedIn({
						name: name,
						surname: surname,
						email: email,
						emailValid: data.loginInformation.emailValid,
						profileInfoValid: data.loginInformation.profileInfoValid,
					})
				);
			}
		});

	const setIconToTab = (imageUrl: String) => {
		let link: any = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement("link");
			link.rel = "icon";
			document.getElementsByTagName("head")[0].appendChild(link);
		}
		link.href = imageUrl;
	};

	ReactDOM.render(
		<Provider store={store}>
			<React.StrictMode>
				<PrimeReactProvider>
					<HashRouter>
						<App />
					</HashRouter>
				</PrimeReactProvider>
			</React.StrictMode>
		</Provider>,
		document.getElementById("root")
	);
}

render();
