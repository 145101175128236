import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import EventIcon from "@mui/icons-material/Event";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import PasswordIcon from "@mui/icons-material/Password";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";
import PortraitIcon from "@mui/icons-material/Portrait";
import RepeatIcon from "@mui/icons-material/Repeat";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { participantClient } from "../../api/services";
import siteClient from "../../api/services/site";
import AvkButton from "../../components/avk-components/AvkButton";
import AvkCalender from "../../components/avk-components/AvkCalendar/AvkCalendar";
import AvkCard from "../../components/avk-components/AvkCard";
import AvkImage from "../../components/avk-components/AvkImage";
import AvkImageCropDialog from "../../components/avk-components/AvkImageCropDialog";
import AvkInputText from "../../components/avk-components/AvkInputText";
import AvkTooltip from "../../components/avk-components/AvkTooltip";
import CreateAccountRequest from "../../dto/request/CreateAccountRequest";
import {
	addToRecaptchaIdList,
	removeFromRecaptchaIdList,
	removeRecaptchaToken,
} from "../../redux/slices/recaptcha-slice";
import { setOpenUserForm } from "../../redux/slices/user-slice";
import { store } from "../../redux/store";
import { setAsShowRegisterResultToLocalStorage } from "../../utils/AuthFunctions";
import {
	checkTckn,
	clearWhiteSpace,
	getBase64FromImageUrl,
	isNullOrUndefinedOrEmpty,
} from "../../utils/CommonFunctions";
import RegisterValidation from "./RegisterValidation";

type RegisterFormikObject = {
	governmentId: string;
	pasaportId: string;
	email: string;
	phone: string;
	name: string;
	surname: string;
	birthDate: Date;
	birthPlace: string;
	password: string;
	passwordRepeat: string;
	// ---
	fatherName: string;
	workPhone: string;
	job: string;
	gender: string;
	education: string;
	grantedBy: string;
	address: string;
	explanation: string;
	picture: Blob;
};

type RegisterUrlParams = {
	uniqueKey: string;
};

type CropperProps = {
	show: boolean;
	image: string;
};

const RegisterPage = () => {
	const refInput = useRef<HTMLInputElement>(null);

	const history = useHistory();

	const [cropperProps, setCropperProps] = useState<CropperProps>({
		show: false,
		image: "",
	});

	const [profilePictureBase64, setProfilePictureBase64] = useState<string>();
	const [profilePicture, setProfilePicture] = useState<string>();

	const userLoggedIn: boolean = useSelector(
		(props: any) => props.user.userLoggedIn
	);

	const recaptchaToken: string = useSelector(
		(props: any) => props.recaptcha.token
	);

	useEffect(() => {
		if (userLoggedIn) {
			history.push("/");
		} else {
			siteClient.getDefaultProfilePicture().then(({ data }) => {
				const blob = new Blob([data], {
					type: "application/pdf",
				});
				const url = URL.createObjectURL(blob);
				getBase64FromImageUrl(url, setProfilePictureBase64);
				setProfilePicture(url);
			});
		}
	}, []); // eslint-disable-line

	const { uniqueKey }: RegisterUrlParams = useParams();
	const tmpEmail = uniqueKey && uniqueKey.includes("@") ? uniqueKey : "";
	const tmpTckn = uniqueKey && checkTckn(uniqueKey) ? uniqueKey : "";
	const tmpPassport =
		uniqueKey && tmpEmail === "" && tmpTckn === "" ? uniqueKey : "";

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: tmpEmail,
			governmentId: tmpTckn,
			pasaportId: tmpPassport,
			name: "",
			surname: "",
			password: "",
			passwordRepeat: "",
		} as RegisterFormikObject,
		validationSchema: RegisterValidation,
		validateOnBlur: true,
		validateOnChange: true,
		onSubmit: () => {
			if (!isNullOrUndefinedOrEmpty(recaptchaToken)) {
				participantClient
					.createAccount({
						profilePictureBase64: profilePictureBase64,
						tckn: clearWhiteSpace(formik.values.governmentId),
						passportNumber: clearWhiteSpace(formik.values.pasaportId),
						email: clearWhiteSpace(formik.values.email),
						phoneNumber: clearWhiteSpace(formik.values.phone),
						name: clearWhiteSpace(formik.values.name),
						surname: clearWhiteSpace(formik.values.surname),
						birthDate: formik.values.birthDate ?? undefined,
						birthPlace: clearWhiteSpace(formik.values.birthPlace),
						password: clearWhiteSpace(formik.values.password),
						recaptchaToken: recaptchaToken,
					} as CreateAccountRequest)
					.then(() => {
						setAsShowRegisterResultToLocalStorage();
						history.push("/register-result");
					})
					.finally(() => {
						store.dispatch(removeRecaptchaToken());
					});
			}
		},
	});

	const recaptchaButtonId = "signUpButton";
	useEffect(() => {
		if (!userLoggedIn) {
			store.dispatch(addToRecaptchaIdList(recaptchaButtonId));
		} else {
			store.dispatch(removeFromRecaptchaIdList(recaptchaButtonId));
		}
		return () => {
			store.dispatch(removeFromRecaptchaIdList(recaptchaButtonId));
		};
	}, [userLoggedIn]); // eslint-disable-line

	useEffect(() => {
		if (!isNullOrUndefinedOrEmpty(recaptchaToken)) {
			console.log("submit test...");
			formik.submitForm();
		}
	}, [recaptchaToken]); // eslint-disable-line

	const gridClassNameLeft = "col-offset-0 col-12 lg:col-offset-1 lg:col-5";
	const gridClassNameRight = "col-offset-0 col-12 lg:col-5";
	const gridClassNameRegister =
		"text-center col-offset-0 col-12 lg:col-offset-8 lg:col-3";
	return (
		<div className="flex align-items-center justify-content-center flex-column h-full w-full">
			{!userLoggedIn && (
				<AvkCard>
					<form onSubmit={formik.handleSubmit}>
						<div className="grid">
							<div className="col-12 text-center">
								<span className="font-bold text-3xl md:text-5xl">
									Yeni Hesap Oluştur
								</span>
							</div>
							<div className={gridClassNameLeft + " pb-0"}>
								<div className="grid my-0 pb-0">
									<div
										className="col-fixed mt-5 pb-0 pr-0 mb-0 relative mr-5"
										style={{ width: "88px" }}
									>
										<AvkImage
											src={profilePicture}
											width="85"
											height="116"
											className="mb-0 image-edit"
											imageStyle={{
												alignSelf: "center",
											}}
											onClick={() => {
												refInput.current?.click();
											}}
										/>
										<i className="pi pi-pencil absolute image-edit-icon top-50 left-50" />
										<input
											ref={refInput}
											className="hidden"
											type="file"
											accept="image/*"
											value={undefined}
											onClick={(e) => {
												e.currentTarget.value = "";
											}}
											onChange={(event: any) => {
												setCropperProps({
													show: true,
													image: URL.createObjectURL(
														event.target.files[0]
													),
												});
											}}
										/>
									</div>
									<div className="col pb-0 pl-0 mb-0">
										<div className="col-12 pl-0 pr-0 pt-0 mb-0">
											<AvkInputText
												fullwidth
												id="name"
												name="name"
												label="Ad"
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
												value={formik.values.name}
												groupIconElement={
													<FormatColorTextIcon fontSize="small" />
												}
												error={
													formik.touched.name &&
													Boolean(formik.errors.name)
												}
											/>
										</div>
										<div className="col-12 pl-0 pr-0">
											<AvkInputText
												fullwidth
												id="surname"
												name="surname"
												label="Soyad"
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
												value={formik.values.surname}
												groupIconElement={
													<FormatColorTextIcon fontSize="small" />
												}
												error={
													formik.touched.surname &&
													Boolean(formik.errors.surname)
												}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className={gridClassNameRight + " pb-0"}>
								<div className="grid my-0 pb-0">
									<div className="col-12 mb-0">
										<AvkInputText
											fullwidth
											id="governmentId"
											name="governmentId"
											label="TC Kimlik No"
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											value={formik.values.governmentId}
											groupIconElement={
												<FingerprintIcon fontSize="small" />
											}
											error={
												formik.touched.governmentId &&
												Boolean(formik.errors.governmentId)
											}
										/>
									</div>
									<div className="col-12 mb-0">
										<AvkInputText
											fullwidth
											id="pasaportId"
											name="pasaportId"
											label="Pasaport No"
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											value={formik.values.pasaportId}
											groupIconElement={
												<PortraitIcon fontSize="small" />
											}
											error={
												formik.touched.pasaportId &&
												Boolean(formik.errors.pasaportId)
											}
										/>
									</div>
								</div>
							</div>
							<div className={gridClassNameLeft}>
								<AvkInputText
									fullwidth
									id="email"
									name="email"
									label="E-posta"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									value={formik.values.email}
									groupIconElement={
										<AlternateEmailIcon fontSize="small" />
									}
									error={
										formik.touched.email &&
										Boolean(formik.errors.email)
									}
								/>
							</div>
							<div className={gridClassNameRight}>
								<AvkInputText
									fullwidth
									id="phone"
									name="phone"
									label="Telefon Numarası"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									value={formik.values.phone}
									groupIconElement={<PhoneIcon fontSize="small" />}
								/>
							</div>
							<div className={gridClassNameLeft}>
								<AvkCalender
									fullwidth
									id="birthDate"
									name="birthDate"
									label="Doğum Tarihi"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									value={formik.values.birthDate}
									dateFormat="yy-mm-dd"
									groupIconElement={<EventIcon fontSize="small" />}
								/>
							</div>
							<div className={gridClassNameRight}>
								<AvkInputText
									fullwidth
									id="birthPlace"
									name="birthPlace"
									label="Doğum Yeri"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									value={formik.values.birthPlace}
									groupIconElement={<PlaceIcon fontSize="small" />}
								/>
							</div>
							<div className={gridClassNameLeft}>
								{/* 
										FIXME: Update password rules !.. 
										Create a component for profile and register page
								*/}
								<AvkTooltip
									showOnFocus
									matchingClassName="password-tooltip"
									content={
										<AvkInputText
											password
											fullwidth
											id="password"
											name="password"
											label="Parola"
											className="password-tooltip"
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											value={formik.values.password}
											groupIconElement={
												<PasswordIcon fontSize="small" />
											}
											error={
												formik.touched.password &&
												Boolean(formik.errors.password)
											}
										/>
									}
									tooltipContent="* Parolanız en az 6 en fazla 24 karakter içermelidir."
								/>
							</div>
							<div className={gridClassNameRight}>
								<AvkInputText
									password
									fullwidth
									id="passwordRepeat"
									name="passwordRepeat"
									label="Parola Tekrar"
									hidePasswordMeter
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									value={formik.values.passwordRepeat}
									groupIconElement={<RepeatIcon />}
									error={
										formik.touched.passwordRepeat &&
										Boolean(formik.errors.passwordRepeat)
									}
								/>
							</div>
							<div className={gridClassNameRegister}>
								<AvkButton
									fullwidth
									id={recaptchaButtonId}
									name={recaptchaButtonId}
									label="HESAP OLUŞTUR"
									type="submit"
									className="mt-3"
								/>
							</div>
							<div className={gridClassNameRegister}>
								<span>Zaten hesabınız var mı?</span>
								<AvkButton
									link
									id="signInButton"
									name="signInButton"
									label="Giriş Yapın"
									type="button"
									className="m-0 p-0 ml-2 text-base"
									onClick={() => {
										store.dispatch(setOpenUserForm(true));
									}}
								/>
							</div>
						</div>
					</form>
				</AvkCard>
			)}

			{cropperProps.show && (
				<AvkImageCropDialog
					src={cropperProps.image ?? ""}
					onHide={() => {
						setCropperProps({ ...cropperProps, show: false });
					}}
					visible={cropperProps.show}
					OkClick={(result) => {
						getBase64FromImageUrl(result, setProfilePictureBase64);
						setProfilePicture(result);
						setCropperProps({ ...cropperProps, show: false });
					}}
				/>
			)}
		</div>
	);
};

export default RegisterPage;
