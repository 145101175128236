import { FunctionComponent } from "react";
import { Programs } from "../program";

const MainPage: FunctionComponent = () => {
	return (
		<div className="flex align-items-center justify-content-center font-bold w-full h-full">
			<Programs />
		</div>
	);
};

export default MainPage;
