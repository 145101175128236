import { Image } from "primereact/image";
import { FunctionComponent, useEffect, useState } from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { certificateClient, programClient } from "../../../api/services";
import AvkButton from "../../../components/avk-components/AvkButton";
import AvkCard from "../../../components/avk-components/AvkCard";
import AvkDataGridView from "../../../components/avk-components/AvkDataGridView";
import { AvkDataGridViewData } from "../../../components/avk-components/AvkDataGridView/AvkDataViewObjects";
import { StatusTypes } from "../../../constants/registerInfo";
import {
	ParticipantProgramDTO,
	ProgramDTO,
} from "../../../dto/response/ProgramDTO";
import { UserSliceObject } from "../../../redux/slices/user-slice";
import "./programList.css";
interface ProgramsProps {}

const Programs: FunctionComponent<ProgramsProps> = (props) => {
	const history = useHistory();

	const userValues: UserSliceObject = useSelector((props: any) => props.user);
	const courseApplyActive: boolean = useSelector(
		(props: any) => props.site.courseApplyActive
	);

	const [userProgramsDataContent, setUserProgramsDataContent] = useState<
		ParticipantProgramDTO[]
	>([]);

	const [programsPageData, setProgramsPageData] = useState<{
		page: number;
		size: number;
		content: ProgramDTO[];
		totalItems: number;
	}>({
		page: 0,
		size: 10,
		content: [],
		totalItems: 0,
	});

	useEffect(() => {
		programClient
			.getProgram(programsPageData.page, programsPageData.size)
			.then((res) => {
				setProgramsPageData({
					...programsPageData,
					page: res.data.currentPage,
					content: [...programsPageData.content, ...res.data.itemList],
					totalItems: res.data.totalItems,
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [programsPageData.page]);

	useEffect(() => {
		if (userValues.userLoggedIn) {
			programClient.getUserPrograms().then((res) => {
				setUserProgramsDataContent(res.data);
			});
		} else {
			setUserProgramsDataContent([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userValues.userLoggedIn]);

	return (
		<AvkCard>
			<div className="flex align-items-center justify-content-center flex-column mx-2 mb-2 w-full h-full">
				{userValues.userLoggedIn && (
					<>
						<div className="w-full">
							<h1>Eğitimlerin</h1>
							{userProgramsDataContent.length > 0 && (
								<AvkDataGridView
									data={userProgramsDataContent.map((item) => {
										return {
											id: item.id,
											header: (
												<Image
													src="https://primefaces.org/cdn/primereact/images/usercard.png"
													alt="Card"
													height="65"
													width="100%"
												/>
											),
											title: item.coursename,
											content: item.explanation?.slice(
												0,
												Math.min(item.explanation.length, 250)
											),
											onClick: (id: number) => {
												history.push("/certification-detail/" + id);
											},
											footerClassName: "text-xl",
											footerContent: (
												<div className="flex flex-row justify-content-end align-items-center mt-2">
													{item.active ? (
														item.status ===
														StatusTypes.PENDING ? (
															"Başvuru Yapıldı"
														) : item.status ===
														  StatusTypes.DECLINED ? (
															"Başvuru Onaylanmadı"
														) : new Date(item.startdate) <
														  new Date() ? (
															"Eğitim devam ediyor"
														) : (
															"Eğitim Başlamak Üzere"
														)
													) : (
														<AiOutlineFilePdf
															className="cursor-pointer"
															size={26}
															onClick={() => {
																certificateClient
																	.getCertificateByProgram(
																		item.programcode
																	)
																	.then((res) => {
																		const blob = new Blob(
																			[res.data],
																			{
																				type: "application/pdf",
																			}
																		);
																		window.open(
																			URL.createObjectURL(
																				blob
																			)
																		);
																	});
															}}
														/>
													)}
												</div>
											),
										} as AvkDataGridViewData;
									})}
								/>
							)}
						</div>
						<div className="w-full border-top-1 border-solid border-400" />
					</>
				)}
				<div className="w-full">
					<h1>Eğitimler</h1>
					{programsPageData.content.length > 0 && (
						<AvkDataGridView
							data={programsPageData.content
								.filter(
									(x) =>
										!userProgramsDataContent.some(
											(y) => y.id === x.id
										)
								)
								.map((item) => {
									return {
										id: item.id,
										header: (
											<Image
												src="https://primefaces.org/cdn/primereact/images/usercard.png"
												alt="Card"
												height="65"
												width="100%"
											/>
										),
										title: item.courseName,
										content: item.explanation?.slice(
											0,
											Math.min(item.explanation.length, 250)
										),
										onClick: (id: number) => {
											history.push("/certification-detail/" + id);
										},
										footerClassName: "text-xl",
										footerContent: courseApplyActive ? (
											<div className="flex flex-row justify-content-end align-items-center mt-2">
												<div className="flex flex-row justify-content-end align-items-center">
													<AvkButton
														size="small"
														onClick={() => {
															history.push(
																"/certification-detail/" +
																	item.id
															);
														}}
														className="mt-2"
														outlined
														label="BAŞVUR"
													/>
												</div>
											</div>
										) : undefined,
									} as AvkDataGridViewData;
								})}
						/>
					)}
					<div
						className={`${
							programsPageData.size * (programsPageData.page + 1) <
							programsPageData.totalItems
								? "flex w-full justify-content-center align-item-center"
								: "hidden "
						}`}
					>
						<div
							className="underline hover:bg-gray-200 cursor-pointer"
							onClick={() => {
								setProgramsPageData({
									...programsPageData,
									page: programsPageData.page + 1,
								});
							}}
						>
							Daha fazla...
						</div>
					</div>
				</div>
			</div>
		</AvkCard>
	);
};
export default Programs;
