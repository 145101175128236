export const loginInfoKey = "login";
export const registerResultKey = "registerResult";
export const emailValidationResultKey = "emailValidationResult";

export const removeLoginFromLocalStorage = () => {
	localStorage.removeItem(loginInfoKey);
};

export const setAsLoginToLocalStorage = () => {
	localStorage.setItem(loginInfoKey, "true");
};

export const checkIsLoginFromLocalStorage = (): boolean => {
	return localStorage.getItem(loginInfoKey) === "true";
};

export const removeShowRegisterResultFromLocalStorage = () => {
	localStorage.removeItem(registerResultKey);
};

export const setAsShowRegisterResultToLocalStorage = () => {
	localStorage.setItem(registerResultKey, "true");
};

export const checkIsShowRegisterResultFromLocalStorage = (): boolean => {
	return localStorage.getItem(registerResultKey) === "true";
};

export const removeShowEmailValidationResultFromLocalStorage = () => {
	localStorage.removeItem(emailValidationResultKey);
};

export const setAsShowEmailValidationResultToLocalStorage = () => {
	localStorage.setItem(emailValidationResultKey, "true");
};

export const checkIsShowEmailValidationResultFromLocalStorage = (): boolean => {
	return localStorage.getItem(emailValidationResultKey) === "true";
};
