import { SplitButton } from "primereact/splitbutton";
import { AvkSplitButtonMenuItem } from "./AvkSplitButtonObjects";

interface AvkSplitButtonProps {
	label: string;
	onClick: () => void;
	menuItems: AvkSplitButtonMenuItem[];
	icon?: JSX.Element | string;
}

const AvkSplitButton = (props: AvkSplitButtonProps) => {
	return (
		<SplitButton
			label={props.label}
			icon={props.icon}
			onClick={props.onClick}
			model={props.menuItems.map((item) => {
				return {
					label: item.label,
					icon: item.icon,
					command: item.onClick,
				};
			})}
		/>
	);
};

export default AvkSplitButton;
