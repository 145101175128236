import * as yup from "yup";
import { isNullOrUndefinedOrEmpty } from "../../utils/CommonFunctions";

export const ResetPasswordValidation = yup.object({
	password: yup
		.string()
		.min(6)
		.max(24)
		.required("Lütfen bir parola belirleyiniz."),
	passwordRepeat: yup
		.string()
		.min(6)
		.max(24)
		.test(
			"passwordsShouldMatch",
			"Parolalar eşleşmiyor. Lütfen parolanızı kontrol ediniz.",
			(value, context) => {
				if (isNullOrUndefinedOrEmpty(value ?? "")) {
					return false;
				}
				return value === context.parent.password;
			}
		),
});
