import React, { useEffect, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import getCroppedImg from "../../../utils/ImageCropFunction";
import AvkButton from "../AvkButton";
import AvkDialog from "../AvkDialog";

interface AvkImageCropDialogProps {
	src: string;
	onHide: (visible: boolean) => void;
	OkClick: (resultImage: string) => void;
	className?: string;
	visible: boolean;
}

type customSize = {
	width: number;
	heigth: number;
};

const AvkImageCropDialog = (props: AvkImageCropDialogProps) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
	const [customSize, setCustomSize] = useState<customSize>({
		width: 600,
		heigth: 800,
	});

	useEffect(() => {
		const image = new Image();
		image.src = props.src;
		image.onload = (me: any) => {
			setCustomSize({
				width: Math.min(image.width, customSize.width),
				heigth: Math.min(image.height, customSize.heigth),
			});
		};
	}, [props.src]); // eslint-disable-line

	return (
		<AvkDialog
			visible={props.visible}
			className={props.className}
			onHide={() => props.onHide(false)}
			maximized
			title="Resim Yükle"
			footer={
				<React.Fragment>
					<AvkButton
						label="İptal"
						icon="pi pi-times"
						severity="danger"
						onClick={() => props.onHide(false)}
					/>
					<AvkButton
						label="Kaydet"
						icon="pi pi-check"
						onClick={async () => {
							try {
								const result = await getCroppedImg(
									props.src,
									croppedAreaPixels,
									0
								);
								props.OkClick(result + "");
							} catch (e) {
								console.error(e);
							}
						}}
					/>
				</React.Fragment>
			}
		>
			<Cropper
				classes={{ containerClassName: "mt-7" }}
				cropSize={{ height: customSize.heigth, width: customSize.width }}
				image={props.src}
				crop={crop}
				zoom={zoom}
				zoomSpeed={0.3}
				aspect={customSize.heigth / customSize.width}
				onCropChange={setCrop}
				onZoomChange={setZoom}
				onCropComplete={(croppedArea, croppedAreaPixels) => {
					setCroppedAreaPixels(croppedAreaPixels);
				}}
			/>
		</AvkDialog>
	);
};

export default AvkImageCropDialog;
