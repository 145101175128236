import { useLocation } from "react-router-dom";
import AvkCard from "../../../components/avk-components/AvkCard";
import { isNullOrUndefinedOrEmpty } from "../../../utils/CommonFunctions";

type CertificateShowProps = {
	file: string;
};

const CertificateShow = () => {
	const { file } = (useLocation().state as CertificateShowProps) ?? {};
	return (
		<AvkCard className="w-fit h-fit p-3 flex flex-row justify-content-center align-items-center">
			{!isNullOrUndefinedOrEmpty(file) ? (
				<>
					<div
						className="w-full text-center text-3xl p-2 "
						style={{ color: "green" }}
					>
						Belge Doğrulama Başarılı
					</div>
					<iframe
						title={"Sertifika"}
						width={1000}
						height={1000}
						src={file}
					></iframe>
				</>
			) : (
				<div
					className="w-full text-center text-3xl p-2 "
					style={{ color: "red" }}
				>
					Belge Doğrulanamadı
				</div>
			)}
		</AvkCard>
	);
};
export default CertificateShow;
