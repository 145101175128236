import { ScrollPanel } from "primereact/scrollpanel";

interface AvkScrollPanelProps {
	height?: string;
	minHeight?: string;
	width?: string;
	children?: React.ReactNode;
	className?: string;
}

const AvkScrollPanel = (props: AvkScrollPanelProps) => {
	return (
		<ScrollPanel
			pt={{ barX: { className: "overflow-auto" } }}
			style={{
				width: props.width ?? "100%",
				height: props.height,
				minHeight: props.minHeight,
			}}
			className={props.className}
		>
			{props.children}
		</ScrollPanel>
	);
};

export default AvkScrollPanel;
