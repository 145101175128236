import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { ReactNode } from "react";

interface AvkInputTextProps {
	onChange: (e: any) => void;
	onBlur: (e: any) => void;
	label: string;
	id: string;
	name?: string;
	value?: string;
	className?: string;
	fullwidth?: boolean;
	error?: boolean;
	required?: boolean;
	placeholder?: string;
	groupIconElement?: JSX.Element;
	password?: boolean;
	hidePasswordMeter?: boolean;
	tabIndex?: number;
	disabled?: boolean;
	icon?: ReactNode;
}

const AvkInputText = (props: AvkInputTextProps) => {
	const fullwidthClass = props.fullwidth ? "w-full " : "";
	const iconElementClassName = props.icon ? " p-input-icon-right " : "";
	let className = props.className ?? "";
	className += props.error ? " p-invalid" : "";

	const baseComponent = props.password ? (
		<div>
			<span
				className={"p-float-label " + fullwidthClass + className}
				style={{ position: "relative" }}
			>
				<Password
					id={props.id}
					placeholder={props.placeholder ?? ""}
					name={props.name}
					className={"p-inputtext-lg " + fullwidthClass + className}
					inputClassName={fullwidthClass + className}
					onChange={props.onChange}
					onBlur={props.onBlur}
					value={props.value}
					required={props.required}
					tabIndex={props.tabIndex ?? 0}
					disabled={props.disabled}
					pt={{
						panel: { className: props.hidePasswordMeter && "hidden" },
					}}
				/>
				<label htmlFor={props.id}>{props.label}</label>
			</span>
		</div>
	) : (
		<div>
			<span
				className={fullwidthClass + className + iconElementClassName}
				style={{ position: "relative" }}
			>
				{props.icon &&
					(typeof props.icon == "string" ? (
						<i className={props.icon.toString()} />
					) : (
						props.icon
					))}
				<span className="p-float-label">
					<InputText
						id={props.id}
						placeholder={props.placeholder ?? ""}
						name={props.name}
						className={"p-inputtext-lg " + fullwidthClass + className}
						onChange={props.onChange}
						onBlur={props.onBlur}
						value={props.value}
						required={props.required}
						tabIndex={props.tabIndex ?? 0}
						disabled={props.disabled}
					/>
					<label htmlFor={props.id}>{props.label}</label>
				</span>
			</span>
		</div>
	);

	return (
		<>
			{props.groupIconElement ? (
				<div
					className={
						"p-inputgroup flex-1 mt-5 " + fullwidthClass + className
					}
				>
					<span className="p-inputgroup-addon w-1">
						{props.groupIconElement}
					</span>
					<div className="flex-grow-1">{baseComponent}</div>
				</div>
			) : (
				<div className="mt-5">{baseComponent}</div>
			)}
		</>
	);
};

export default AvkInputText;
