import * as yup from "yup";

const SemHeaderLoginValidation = yup.object({
	uniqueKey: yup.string().required("Lütfen kullanıcı adınızı giriniz."),
	password: yup.string().when("isPasswordStep", {
		is: true,
		then: (shema) => shema.required("Lütfen parolanızı giriniz."),
		otherwise: (schema) => schema.notRequired(),
	}),
});

export default SemHeaderLoginValidation;
